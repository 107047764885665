// import packages
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using react-router for navigation
import { motion } from 'motion/react';

// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

export const LoginPage = () => {
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();

	const handleLogin = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});

			const data = await response.json();
			if (response.ok) {
				// Store the JWT token in local storage or a state management solution
				localStorage.setItem('userToken', data.token);
				navigate('/podcast', { replace: true }); // Redirect to a protected route after login
			} else {
				setErrorMessage(data.message || 'Login failed. Please try again.');
			}
		} catch (error) {
			console.error(error);
			setErrorMessage('An error occurred during login. Please try again later.');
		}
	};

	const renderErrorResponse = () => {
		if (errorMessage) {
			const containerVariants = {
				hidden: { opacity: 0, scale: 0.95 },
				visible: { opacity: 1, scale: 1 },
				exit: { opacity: 0, scale: 1 }, // Subtle scale-out effect
			};

			const textVariants = {
				hidden: { opacity: 0, y: -10 },
				visible: { opacity: 1, y: 0 },
				exit: { opacity: 0, y: 10 }, // Smooth exit without harsh offset
			};

			const containerTransition = {
				type: 'spring',
				stiffness: 120,
				damping: 20,
				delay: 0.1,
				duration: 0.5, // Added duration for smoother fade-out
			};

			const textTransition = {
				type: 'spring',
				stiffness: 120,
				damping: 20,
				delay: 0.2,
				duration: 0.4, // Slightly faster fade-out for the text
			};

			return (
				<motion.div
					className={`w-full px-4 py-4 rounded-lg drop-shadow-lg bg-red-500`}
					variants={containerVariants}
					initial="hidden"
					animate="visible"
					exit="exit"
					transition={containerTransition}>
					<motion.p
						className="text-zinc-900 maintext tracking-wide text-center font-semibold"
						variants={textVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
						transition={textTransition}>
						{errorMessage}
					</motion.p>
				</motion.div>
			);
		}
	};

	return (
		<div>
			<Navbar />
			<div className="bg-gray-800">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32 my-4">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-2">
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ ease: 'easeOut', delay: 0.3, duration: 2 }}
							className="bg-slate-200 py-8 px-8 text-zinc-900 rounded-xl drop-shadow-xl space-y-4">
							<div className="">
								<motion.p
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ ease: 'easeOut', delay: 0.4, duration: 1.3 }}
									className="uppercase logo font-black text-[28px] text-center">
									welcome back!
								</motion.p>
							</div>
							<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: 'easeOut', delay: 0.5, duration: 1.5 }}>
								<p className="logo uppercase font-black text-[12px]">Email</p>
								<motion.input
									type="email"
									placeholder="example@example.com"
									value={email}
									onChange={e => setEmail(e.target.value)}
									required
									className="px-4 py-2 pl-4 rounded-lg w-full text-zinc-800"
									onKeyDown={e => {
										if (e.key === 'Enter') {
											handleLogin(); // Call handleLogin when Enter key is pressed
										}
									}}
									initial={{ scale: 1, boxShadow: '0px 0px 0px #1f2937' }}
									whileFocus={{
										scale: 1.05,
										boxShadow: '0px 0px 15px #d9f99d',
									}}
									transition={{
										type: 'spring',
										stiffness: 200,
										damping: 20,
									}}
								/>
							</motion.div>

							{renderErrorResponse()}

							<motion.div
								whileHover={{ scale: 1.05, fontWeight: 900 }}
								whileTap={{
									scale: 1.1,
									transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
								}}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.8, duration: 1.7 }}
								onClick={handleLogin}
								className="rounded-xl bg-luif-cta-2 text-luif-bg px-4 py-2 text-[16px] logo uppercase font-semibold text-center w-full cursor-pointer">
								Login
							</motion.div>

							<motion.div
								whileHover={{ scale: 1.05, fontWeight: 900 }}
								whileTap={{
									scale: 1.1,
									transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
								}}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.9, duration: 1.9 }}
								onClick={() => navigate('/', { replace: false })}
								className="rounded-xl bg-gray-800 text-zinc-50 border border-zinc-50 px-4 py-2 text-[16px] logo uppercase font-semibold text-center w-full cursor-pointer">
								Signup
							</motion.div>
						</motion.div>
					</div>
					<div></div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
