import React from 'react';
import { motion } from 'framer-motion';

export const StockChartLoading = () => {
	return (
		<div className="flex justify-center items-center min-h-screen bg-gray-800">
			<motion.svg width="150" height="100" viewBox="0 0 150 100" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 80 L30 60 L50 70 L80 30 L100 40 L130 20" stroke="#4ADE80" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
				<motion.path
					d="M0 80 L30 60 L50 70 L80 30 L100 40 L130 20"
					stroke="#22C55E"
					strokeWidth="2"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round"
					initial={{ pathLength: 0 }}
					animate={{ pathLength: 1 }}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						repeat: Infinity,
						repeatType: 'reverse',
					}}
				/>
			</motion.svg>
		</div>
	);
};
