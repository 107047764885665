// Import packages
import React from 'react';

export const HamburgerIcon = () => {
	return (
		<svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4 18L20 18" stroke="#D0D0E1" strokeWidth="2" strokeLinecap="round" />
			<path d="M4 12L20 12" stroke="#D0D0E1" strokeWidth="2" strokeLinecap="round" />
			<path d="M4 6L20 6" stroke="#D0D0E1" strokeWidth="2" strokeLinecap="round" />
		</svg>
	);
};
