// import packages
import { useNavigate } from 'react-router-dom';

// import component
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

// import icons
import { Arrow } from '../../public/assets/chev/index.jsx';

export const Glossary = () => {
	const navigate = useNavigate();

	return (
		<>
			<Navbar />
			<div className="">
				{/* header section */}
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32">
					<div></div>
					<div className="text-luif-text my-4 col-span-4 py-12 sm:px-8 px-8 drop-shadow-xl">
						<div className="space-y-2">
							<p className="text-[32px] logo font-black tracking-normal">Financial Glossary – Your Guide to Key Finance Terms and Forms</p>
							<p className="text-[20px] subText font-normal tracking-tight">
								Understanding financial terms and SEC filings is crucial for making informed investment decisions. Our financial glossary simplifies complicated
								terms and documents, helping you navigate the world of investing with confidence.
							</p>
							<p className="text-[20px] subText font-normal tracking-tight">
								We break down essential finance forms like Form 4, Form S-1, and more, so you can understand how insider trading works, evaluate initial public
								offerings (IPOs), and analyze company filings. Whether you're an experienced investor or just starting out, this glossary will give you the
								knowledge you need to make smarter financial choices.
							</p>
						</div>
					</div>
					<div></div>
				</div>

				<div className="bg-slate-50">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32">
						<div></div>
						<div className="text-zinc-900 my-12 col-span-4 py-4 sm:px-8 px-8 drop-shadow-xl">
							<div className="space-y-2 bg-slate-100 drop-shadow-lg rounded-md px-8 py-12">
								<p className="text-[28px] logo font-black tracking-normal">Key SEC Forms for Investors:</p>

								<li>
									<span className="font-semibold underline tracking-tight text-[16px]">Form 4:</span> Insider Trading Reports – Understand the buying and selling
									actions of company executives and insiders.
								</li>

								<li>
									<span className="font-semibold underline tracking-tight text-[16px]">Form S-1:</span> IPO Registration – Learn what’s behind a company’s plan to
									go public and what it means for your investments.
								</li>
								<li>
									<span className="font-semibold underline tracking-tight text-[16px]">More forms coming soon:</span> We are continually updating the glossary
									with new forms and terms that every investor should know.
								</li>
							</div>
						</div>
						<div></div>
					</div>
				</div>

				{/* <hr className="opacity-75 w-full my-8" /> */}

				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32">
						<div></div>
						<div className="text-slate-50 my-4 col-span-4 py-12 sm:px-8 px-8 drop-shadow-xl">
							<p className="text-[20px] font-normal tracking-tight">
								Explore the glossary below to find in-depth explanations of finance forms and terms. Knowledge is power when it comes to investing—get started today
								and take control of your financial future.
							</p>
						</div>
						<div></div>
					</div>
				</div>

				{/* <hr className="opacity-75 w-full my-8" /> */}

				<div className="bg-slate-50">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32">
						<div></div>
						<div className="my-4 col-span-4 py-12 sm:px-8 px-8">
							<div className="grid grid-cols-1 sm:grid-cols-2 space-y-4 sm:space-y-0 sm:space-x-4">
								<div
									className="grid grid-cols-2 bg-slate-300 py-4 px-8 rounded-lg text-zinc-900 cursor-pointer drop-shadow-xl border border-zinc-900"
									onClick={() => navigate('/glossary/form-4', { replace: false })}>
									<div className="flex justify-start">
										<p className="logo font-black tracking-wider text-[20px] hover:text-luif-cta-2">Form 4</p>
									</div>
									<div className="flex justify-end">
										<div className="h-8 w-8 fill-zinc-900 hover:fill-luif-cta-2">
											<Arrow />
										</div>
									</div>
								</div>
								<div
									className="grid grid-cols-2 bg-slate-300 py-4 px-8 rounded-lg text-zinc-900 cursor-pointer drop-shadow-xl border border-zinc-900"
									onClick={() => navigate('/glossary/form-s-1', { replace: false })}>
									<div className="flex justify-start">
										<p className="logo font-black tracking-wider text-[20px] hover:text-luif-cta-2">Form S-1</p>
									</div>
									<div className="flex justify-end">
										<div className="h-8 w-8 fill-zinc-900 hover:fill-luif-cta-2">
											<Arrow />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>
			<div></div>
			<Footer />
		</>
	);
};
