// import components
import { Navbar } from '../navbar/index.jsx';
import { Footer } from '../footer/index.jsx';
import { FormS1Quiz } from '../quizzes/form_s1.jsx';

export const FormS1 = () => {
	return (
		<>
			<Navbar />
			<div className="sm:grid sm:grid-cols-4 mx-auto px-4 sm:px-0">
				<div></div>
				<div className="text-luif-text my-4 space-y-4 col-span-2 bg-zinc-900 rounded-xl py-4 sm:px-8 px-4 drop-shadow-xl">
					<p className="logo font-black tracking-tight text-[28px]">Form S-1</p>
					<p className="logo font-black tracking-tight text-[12px]">Last updated: October 2024</p>

					<hr className="w-full" />

					<div className="space-y-4">
						<p className="mainText text-[24px] tracking-tight font-black">Introduction to Form S-1</p>
						<div className="space-y-4">
							<p className="subText text-[16px] tracking-normal font-light">
								So, you’ve probably heard about companies “going public” or raising funds by issuing new stock, but what’s really going on behind the scenes? Enter
								<span className="font-bold"> Form S-1</span>—the document every company needs to file with the SEC (Securities and Exchange Commission) before they
								can sell shares to the public. Whether it’s for an initial public offering (IPO) or simply raising more capital after being public, this form is
								where the company tells its story, discloses key financials, and lets you in on their plans for all the cash they’re hoping to raise.
							</p>
							<p className="subText text-[16px] tracking-normal font-light">
								Reading a Form S-1 can feel a bit like reading a manual for a car you’ve never driven—it’s packed with details, but once you understand the
								language, it starts to make sense. In this guide, I’ll help you decode this form, especially the “Use of Proceeds” section, where companies reveal
								what they plan to do with the money they’re raising. Ready to dive in?
							</p>
						</div>

						<hr className="opacity-75" />
					</div>

					<div className="space-y-4">
						<p className="mainText text-[24px] tracking-tight font-black">FAQs About Form S-1</p>
						<p className="subText text-[20px] tracking-tight font-semibold my-2">
							Before we go deeper, let’s quickly answer some common questions about the form to set the stage.
						</p>
						<p className="subText text-[16px] tracking-normal font-light">
							1. <span className="font-bold">What is Form S-1?</span> Form S-1 is the SEC filing companies must submit before they sell shares to the public. It’s
							packed with important information like financials, business strategy, risks, and how they plan to use the money they raise.
						</p>
						<p className="subText text-[16px] tracking-normal font-light">
							2. <span className="font-bold">Is Form S-1 only for IPOs?</span> Nope! While companies use it to go public, they also file a new S-1 if they want to
							raise additional funds after already being public.
						</p>
						<p className="subText text-[16px] tracking-normal font-light">
							3. <span className="font-bold">Why should I care about Form S-1?</span> As an investor, this form is your window into the company’s financial health,
							growth strategy, and risks. It’s where you can assess if they’re a good investment or if there are red flags.
						</p>
						<p className="subText text-[16px] tracking-normal font-light">
							4. <span className="font-bold">What should I focus on in the Form S-1?</span> Focus on sections like “Use of Proceeds,” “Risk Factors,” and “Business
							Overview.” These give you a clear sense of how the company plans to grow and what challenges it faces.
						</p>
						<p className="subText text-[16px] tracking-normal font-light">
							5. <span className="font-bold">How do I know if a company is being transparent?</span> Vague language in sections like “Use of Proceeds” is a red flag.
							The more specific they are about their plans, the more confident you can be in their strategy.
						</p>
						<hr className="opacity-75" />
					</div>

					<div className="space-y-4">
						<p className="mainText text-[24px] tracking-tight font-black">Important Sections in Form S-1</p>
						<p className="subText text-[20px] tracking-tight font-semibold my-2">
							Now that you’re more familiar with what Form S-1 is and why it matters, let’s look at some of the key sections you’ll encounter:
						</p>

						<li className="subText text-[16px] tracking-normal font-light">
							<span className="font-bold underline trackng-tight">Business Overview</span>: Here, the company gives an outline of what they do, their market, and
							their business model.
						</li>
						<li className="subText text-[16px] tracking-normal font-light">
							<span className="font-bold underline trackng-tight">Risk Factors</span>: This section lists all the things that could go wrong. It’s usually lengthy
							because companies need to protect themselves legally, but don’t skip over it—there are often valuable insights hidden in the risks.
						</li>
						<li className="subText text-[16px] tracking-normal font-light">
							<span className="font-bold underline trackng-tight">Financial Statements</span>: This is where you’ll find the company’s income statement, balance
							sheet, and cash flow. If numbers are your thing, this section is a goldmine.
						</li>
						<li className="subText text-[16px] tracking-normal font-light">
							<span className="font-bold underline trackng-tight">Use of Proceeds</span>: Perhaps the most important section for any investor. Here, the company
							details how they plan to use the money they raise.
						</li>
						<hr className="opacity-75" />
					</div>

					<div className="space-y-4">
						<p className="mainText text-[24px] tracking-tight font-black">Decoding the "Use of Proceeds" Section</p>
						<p className="subText text-[20px] tracking-tight font-semibold my-2">
							Here’s where the real detective work begins. The “Use of Proceeds” section tells you how the company plans to spend the money raised from selling its
							stock. But companies often use vague language, so it's crucial to read between the lines to see what they're really saying.
						</p>

						<p className="subText text-[20px] tracking-tight font-normal">Common Phrases and What They Really Mean:</p>

						<div className="space-y-2">
							<p className="subText text-[20px] tracking-normal font-light">
								1. <span className="font-semibold">"General Corporate Purposes"</span>
							</p>
							<p className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What They Say:</span> “We plan to use the funds for general corporate purposes.”
							</p>
							<p className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What It Means:</span> This could mean anything, from paying down debt to funding expansion.
								It’s purposely vague, so always check if they give more details elsewhere in the form.
							</p>
							<p className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">How to Decode It:</span> Look for clues in sections like “Business Overview” or
								“Management’s Discussion.” If the company isn’t specific, it might indicate they don’t have a clear strategy or are hesitant to reveal too much.
							</p>
						</div>

						<div className="space-y-2">
							<p className="subText text-[20px] tracking-normal font-light">
								2. <span className="font-semibold">"Working Capital"</span>
							</p>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What They Say:</span> “A portion of the proceeds will be used for working capital.”
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What It Means:</span> This typically means the company needs cash to cover day-to-day
								expenses like payroll, inventory, and rent.
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">How to Decode It:</span> If a large portion of proceeds is going toward working capital, it
								could mean the company is struggling to maintain its operations. Compare this with their current cash reserves to get a better sense of their
								financial health.
							</li>
						</div>

						<div className="space-y-2">
							<p className="subText text-[20px] tracking-normal font-light">
								3. <span className="font-semibold">"Strategic Investments"</span>
							</p>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What They Say:</span> “We plan to make strategic investments with the proceeds.”
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What It Means:</span> They want to grow, whether through acquiring new technology,
								expanding into new markets, or hiring more staff.
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">How to Decode It:</span> Check if these investments align with their core business. If
								they’re investing in areas they’re unfamiliar with, that could be risky.
							</li>
						</div>

						<div className="space-y-2">
							<p className="subText text-[20px] tracking-normal font-light">
								4. <span className="font-semibold">"Debt Repayment"</span>
							</p>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What They Say:</span> “Proceeds will be used to repay existing indebtedness.”
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What It Means:</span> They’re using the money to pay off debt.
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">How to Decode It:</span> While paying off debt is often a smart move, if too much of the
								proceeds are going toward this, it could indicate the company is over-leveraged. Look for the interest rates on that debt to assess whether this is
								a positive step or a warning sign.
							</li>
						</div>

						<div className="space-y-2">
							<p className="subText text-[20px] tracking-normal font-light">
								<span className="font-semibold">5. "Acquisitions"</span>
							</p>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What They Say:</span> “We may use a portion of the proceeds for potential acquisitions.”
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">What It Means:</span> They plan to buy other companies or assets to help them grow.
							</li>
							<li className="subText text-[16px] tracking-normal font-light">
								<span className="font-semibold underline tracking-tight">How to Decode It:</span> Ask yourself—does the company have a good track record with
								acquisitions? Mergers and acquisitions (M&A) can boost growth, but they can also be risky if not executed well.
							</li>
						</div>
						<hr className="opacity-75" />
					</div>

					<FormS1Quiz />

					<div className="space-y-4">
						<p className="mainText text-[24px] tracking-tight font-black">Support Our Mission</p>
						<p className="subText text-[16px] tracking-normal font-light">
							If you’ve found this breakdown helpful, supporting content like this allows us to continue creating easy-to-understand guides for important financial
							topics. By donating, you’re helping us provide more tools and resources for people just like you who want to make informed decisions in the world of
							investing. Every bit counts, so if you’re able, please consider contributing to support our mission.
						</p>
						<div className="my-8">
							<a
								href="https://square.link/u/tFtalKxd"
								className="rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[16px] mainText font-bold hover:tracking-widest">
								Support Us!
							</a>
						</div>
					</div>
				</div>
				<div></div>
			</div>
			<Footer />
		</>
	);
};
