// import component
// import { Form4 } from '../components/glossary/form_4';
import { Navbar } from '../navbar/index.jsx';
import { Footer } from '../footer/index.jsx';

// import assets
import form4Top from '../../../public/assets/form_4_top.jpg';
import dateTrans from '../../../public/assets/date_transaction.jpg';
import shares from '../../../public/assets/shares.jpg';
import price from '../../../public/assets/price.jpg';
import ownership from '../../../public/assets/ownership.jpg';
import appleFilings from '../../../public/assets/apple_filings.jpg';
import sec_filings from '../../../public/assets/sec_filings.png';
import sec_google from '../../../public/assets/sec_google.png';
import codes from '../../../public/assets/codes.png';

export const Form4 = () => {
	return (
		<>
			<Navbar />
			<div className="bg-slate-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12 text-zinc-900">
						<h1 className="text-[32px] logo uppercase font-black">Form 4</h1>
						<p className="text-[16px] subText font-normal tracking-tight break-words">Last updated: November 2024</p>
					</div>
					<div></div>
				</div>
			</div>

			<div className="space-y-20 my-12">
				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-50">
							<div className="space-y-4">
								<h1 className="text-[32px] logo uppercase font-black">What is Form 4?</h1>

								<p className="subText text-[16px] tracking-normal font-light">
									Form 4 is a mandatory SEC filing that reports insider trading activity. This form is submitted when company insiders, such as executives,
									directors, or major shareholders, buy or sell shares of their own company. It is designed to provide transparency into insider trading, helping
									investors make informed decisions.
								</p>

								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={form4Top} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-50">
							<div className="space-y-4">
								<h1 className="text-[32px] logo uppercase font-black">Why is Form 4 Important?</h1>
								<p className="subText text-[16px] tracking-normal font-light">
									Form 4 filings help investors track insider activity. When insiders are buying, it could signal confidence in the company's future. Conversely,
									significant insider selling might raise questions about the company's outlook. Monitoring Form 4 can provide valuable insights into a company’s
									stock potential.
								</p>
							</div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-50">
							<div className="space-y-4">
								<h1 className="text-[32px] logo uppercase font-black">Key Information on Form 4</h1>
								<p className="logo text-[20px] tracking-normal font-black">What to Look for:</p>
								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="underline font-black text-luif-text">Transaction Date:</span> When the transaction occurred.
									</p>
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={dateTrans} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="underline font-black text-luif-text">Number of Shares:</span> The amount of shares bought or sold.
									</p>
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={shares} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="underline font-black text-luif-text">Price:</span> The price at which the transaction took place.
									</p>
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={price} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>

								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="underline font-black text-luif-text">Nature of Ownership:</span> Whether shares are owned directly or indirectly.
									</p>
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={ownership} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-50">
							<div className="space-y-4">
								<h1 className="text-[32px] logo uppercase font-black">Pros and Cons of Form 4</h1>
								<div className="grid grid-cols-2">
									<div className="text-left">
										<p className="logo text-[20px] tracking-normal font-black">Pros:</p>
										<p className="subText text-[16px] tracking-normal font-light">It gives transparency into insider sentiment and behavior.</p>
										<p className="subText text-[16px] tracking-normal font-light">Helps investors gauge insider confidence.</p>
									</div>
									<div className="text-left">
										<p className="logo text-[20px] tracking-normal font-black">Cons:</p>
										<p className="subText text-[16px] tracking-normal font-light">
											Insiders may sell shares for reasons unrelated to company performance, such as personal financial needs.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-50">
							<div className="space-y-4">
								<h1 className="text-[32px] logo uppercase font-black">How to Use Form 4 Information</h1>
								<p className="subText text-[20px] tracking-tight font-black">Application:</p>
								<p className="subText text-[16px] tracking-normal font-light">
									Investors can analyze Form 4 filings to spot trends in insider behavior. Multiple insider purchases could indicate strong belief in the
									company’s growth, while insider sales might signal caution. These patterns are useful in evaluating a company’s potential stock movement.
								</p>
								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={appleFilings} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-50">
							<div className="space-y-4">
								<h1 className="text-[32px] logo uppercase font-black">Where Can You Find Form 4?</h1>
								<p className="subText text-[20px] tracking-tight font-black">Sources:</p>
								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<p className="subText text-[16px] tracking-normal font-light">
										Step 1: Type in your search engine {'>'} [company] + investor relations + sec filings
									</p>
									<p className="subText text-[16px] tracking-normal font-light">Example: palantir investor relations</p>
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={sec_filings} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>

								<div className="bg-slate-700 px-4 py-4 rounded-xl drop-shadow-xl">
									<p className="subText text-[16px] tracking-normal font-light">Step 2: Click on sec filing from the company you're looking into.</p>
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={sec_google} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>

			<div className="bg-slate-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-0 space-y-0 text-zinc-900">
						<div className="my-16 space-y-16">
							<div className="space-y-4">
								<p className="mainText text-[32px] tracking-tight font-black">Form 4: SEC Transaction Codes</p>

								<p className="subText text-[16px] tracking-normal font-light">
									Form 4 is used to disclose changes in ownership of a company’s securities by its officers, directors, and major shareholders. Each transaction
									has its own code, and here’s what they mean:
								</p>

								<div className="bg-slate-300 px-4 py-4 rounded-xl drop-shadow-xl">
									<div className="rounded-xl overflow-clip drop-shadow-xl">
										<img src={codes} className="my-4" alt="image of apple inc form 4 sec filing" />
									</div>
								</div>
							</div>

							<div className="space-y-4">
								<div>
									<p className="subText text-[20px] mt-2 tracking-tight font-black">General Transaction Codes:</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">P</span> – Buying shares on the market or privately.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">S</span> – Selling shares on the market or privately.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">V</span> – Reporting a transaction earlier than required.
									</p>
								</div>

								<div>
									<p className="subText text-[20px] mt-2 tracking-tight font-black">Rule 16b-3 Transaction Codes:</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">A</span> – Received shares as a grant or award.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">D</span> – Gave back shares to the company (issuer).
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">F</span> – Used shares to pay taxes or exercise costs.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">I</span> – A move involving shares decided by a broker.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">M</span> – Exercised or converted a security under special rules.
									</p>
								</div>

								<div>
									<p className="subText text-[20px] mt-2 tracking-tight font-black">Derivative Securities Codes:</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">C</span> – Converted a derivative (like options) into actual stock.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">E</span> – A short position expired.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">H</span> – A long position expired, with something of value received.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">O</span> – Exercised a stock option that's out of the money (less than market price).
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">X</span> – Exercised a stock option that's at or in the money (equal to or above market price).
									</p>
								</div>

								<div>
									<p className="subText text-[20px] mt-2 tracking-tight font-black">Other Exempt and Small Acquisition Codes:</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">G</span> – Gave shares as a gift.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">L</span> – Bought a small number of shares.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">W</span> – Inherited shares.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">Z</span> – Deposited or withdrew shares from a voting trust.
									</p>
								</div>

								<div>
									<p className="subText text-[20px] mt-2 tracking-tight font-black">Other Transaction Codes:</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">J</span> – Something else happened (needs more details).
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">K</span> – Transaction involved an equity swap.
									</p>
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black">U</span> – Sold shares during a change of control.
									</p>
								</div>
							</div>

							<div className="my-4 drop-shadow-xl">
								<div className="bg-slate-300 text-zinc-900 rounded-lg my-8 py-8 px-4 w-full border border-zinc-900">
									<p className="subText text-[16px] tracking-normal font-light">
										<span className="font-black tracking-tight">Bonus Tip:</span> Form 4 shows insider actions, which can hint at their belief in the company’s
										future. Keep an eye on <span className="font-black underline">purchases (Code P)</span> and{' '}
										<span className="font-black underline">sales (Code S)</span> to spot insider <span className="underline">confidence</span> or{' '}
										<span className="underline">caution!</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div></div>
			</div>

			<div className="">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12 text-zinc-50">
						<div className="space-y-4">
							<h1 className="text-[32px] logo uppercase font-black">Support Our Mission</h1>
							<p className="text-[16px] subText font-normal tracking-tight break-words">
								If you found this explanation of Form 4 helpful, consider supporting our mission to provide free, accessible financial education. Your donation
								helps us create more valuable content and keeps this resource available to everyone.
							</p>
						</div>
						<div className="my-12">
							<a
								href="https://square.link/u/tFtalKxd"
								className="rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[16px] mainText font-bold hover:tracking-widest">
								Support Us!
							</a>
						</div>
					</div>
					<div></div>
				</div>
			</div>
			<Footer />
		</>
	);
};
