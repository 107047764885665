// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

export const Tos = () => {
	return (
		<>
			<Navbar />
			<div className="text-luif-text mx-auto px-8 sm:px-40 my-auto py-8 space-y-4">
				<h1 className="logo font-black text-[24px]">Terms of Service</h1>

				<div>
					<p className="mainText font-black text-[20px]">1. Acceptance of Terms</p>
					<p className="subText font-thin text-[16px]">
						By accessing or using the LVLUPINFINANCE website, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms,
						please do not use the website.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">2. Use of Website</p>
					<p className="subText font-thin text-[16px]">
						Our website is hosted on Render.com, and we provide links to external platforms such as TikTok, YouTube, and Square for payment processing. By using these
						links, you agree to follow the respective terms and conditions of these third-party platforms.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">3. Payment Processing</p>
					<p className="subText font-thin text-[16px]">
						We use Square for secure donation processing. By making a donation via Square, you agree to Square’s Terms of Service. LVLUPINFINANCE does not store or
						handle your payment information directly. Please refer to Square’s privacy and security policies for more information on how your payment data is handled.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">4. Analytics and Data Collection</p>
					<p className="subText font-thin text-[16px]">
						Our website uses SimpleAnalytics, a privacy-friendly alternative to Google Analytics, to collect anonymous data about site visits. This data helps us
						improve our content and user experience. SimpleAnalytics does not use cookies and does not collect any personally identifiable information.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">5. External Links</p>
					<p className="subText font-thin text-[16px]">
						Our site contains links to external content hosted on platforms such as TikTok and YouTube. We are not responsible for the content or privacy practices of
						these platforms. By clicking on external links, you agree to the terms of use and privacy policies of the respective platforms.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">6. Intellectual Property</p>
					<p className="subText font-thin text-[16px]">
						All content on this site, including but not limited to text, images, and videos, is the intellectual property of LVLUPINFINANCE or its licensors. You may
						not reproduce, distribute, or modify any part of this site without prior written consent.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">7. Limitation of Liability</p>
					<p className="subText font-thin text-[16px]">
						LVLUPINFINANCE is not liable for any damages that may arise from your use of the site or from your reliance on the information provided here. We are not
						responsible for any issues arising from third-party services such as Square, TikTok, YouTube, or SimpleAnalytics.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">8. Amendments</p>
					<p className="subText font-thin text-[16px]">
						We reserve the right to modify these Terms of Service at any time. Any changes will be posted on this page, and continued use of the website constitutes
						acceptance of the revised terms.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">9. Contact Us</p>
					<p className="subText font-thin text-[16px]">If you have any questions or concerns regarding these Terms of Service, please contact us at:</p>
					<p className="subText font-thin text-[16px]">Email: lvlupinfinance@gmail.com</p>
				</div>
			</div>
			<Footer />
		</>
	);
};
