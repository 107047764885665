// import packages
import React from 'react';

export const Footer = () => {
	return (
		<div className="bg-gray-900 text-white py-8 border-t-2 border-luif-cta-2 border-opacity-20">
			<div className="mx-auto px-8 grid grid-cols-1 md:grid-cols-3 items-end space-y-8">
				{/* Left: Short Brand Description & Social Links */}
				<div className="space-y-4 h-full mx-auto sm:px-12 py-4 sm:py-0">
					<h3 className="text-[20px] font-black">LVLUPINFINANCE</h3>
					<p className="text-[16px] subText font-light">
						Bite-sized, actionable insights to help you invest better. Support us and be part of a growing community of smart investors.
					</p>
					<div className="flex justify-evenly space-x-4 text-center logo font-semibold">
						{/* Add your actual social links here */}
						<a href="https://tiktok.com/@lvlupinfinance" className="hover:text-green-400 py-2 px-4 bg-slate-800 rounded-xl w-full">
							TikTok
						</a>
						<a href="https://www.youtube.com/@lvlupinfinance" className="hover:text-green-400 py-2 px-4 bg-slate-800 rounded-xl w-full">
							YouTube
						</a>
					</div>
				</div>

				{/* <div className="mx-auto sm:px-8 border border-white text-center"> */}
				<div className="h-full mx-auto sm:px-12 py-4 sm:py-0 flex items-center">
					<div className="grid grid-cols-1 space-y-8">
						<p className="logo font-black text-[16px]">contact us: lvlupinfinance@gmail.com</p>
						<hr className="opacity-50 w-full mx-auto px-4" />
						<div className="flex justify-evenly space-x-4 text-center logo font-semibold">
							<a href="/privacy-policy" className="underline logo font-black tracking-tighter hover:tracking-wide">
								Privacy Policy
							</a>
							<a href="/terms-of-service" className="underline logo font-black tracking-tighter hover:tracking-wide">
								Terms of Service
							</a>
						</div>
					</div>
				</div>

				{/* Right: Call to Action for Support */}
				<div className="text-left sm:text-right space-y-4 h-full mx-auto sm:px-12 py-4 sm:py-0">
					<h3 className="text-[20px] font-black">Support Us</h3>
					<p className="text-[16px] subText font-light">Your support helps us create more high-quality financial content for the community.</p>
					<a
						href="https://square.link/u/tFtalKxd"
						className="uppercase inline-block px-8 py-4 bg-luif-cta-2 text-luif-bg font-bold rounded-xl text-[16px] hover:font-black">
						Support Us
					</a>
				</div>
			</div>
		</div>
	);
};
