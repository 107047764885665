// import packages
import { useState } from 'react';

export const FormS1Quiz = () => {
	const [toggleA1, setToggleA1] = useState(false);
	const [toggleA2, setToggleA2] = useState(false);
	const [toggleA3, setToggleA3] = useState(false);
	const [toggleA4, setToggleA4] = useState(false);
	const [toggleA5, setToggleA5] = useState(false);

	const renderA1 = () => {
		if (toggleA1) {
			return (
				<>
					<div onClick={() => setToggleA1(!toggleA1)} className="subText text-[16px] tracking-normal font-normal">
						<span className="font-semibold">Answer:</span> B) They might not be transparent. “General corporate purposes” is vague, so always look deeper into other
						sections to assess their real strategy.
					</div>
				</>
			);
		} else {
			return <p onClick={() => setToggleA1(!toggleA1)}>Reveal Answer</p>;
		}
	};

	const renderA2 = () => {
		if (toggleA2) {
			return (
				<>
					<div onClick={() => setToggleA2(!toggleA2)} className="subText text-[16px] tracking-normal font-normal">
						<span className="font-semibold">Answer:</span> B) Investigate their acquisition history. Successful M&A can boost growth, but it’s important to see if they
						have experience with this.
					</div>
				</>
			);
		} else {
			return <p onClick={() => setToggleA2(!toggleA2)}>Reveal Answer</p>;
		}
	};

	const renderA3 = () => {
		if (toggleA3) {
			return (
				<>
					<div onClick={() => setToggleA3(!toggleA3)} className="subText text-[16px] tracking-normal font-normal">
						<span className="font-semibold">Answer:</span> D) All of the above. Debt repayment is generally good, but you’ll want to know the details to see how it
						impacts their future.
					</div>
				</>
			);
		} else {
			return <p onClick={() => setToggleA3(!toggleA3)}>Reveal Answer</p>;
		}
	};

	const renderA4 = () => {
		if (toggleA4) {
			return (
				<>
					<div onClick={() => setToggleA4(!toggleA4)} className="subText text-[16px] tracking-normal font-normal">
						<span className="font-semibold">Answer:</span> B) A large amount dedicated to working capital suggests they may be struggling with day-to-day expenses.
					</div>
				</>
			);
		} else {
			return <p onClick={() => setToggleA4(!toggleA4)}>Reveal Answer</p>;
		}
	};

	const renderA5 = () => {
		if (toggleA5) {
			return (
				<>
					<div onClick={() => setToggleA5(!toggleA5)} className="subText text-[16px] tracking-normal font-normal">
						<span className="font-semibold">Answer:</span> A) Ensure that the investments align with the company’s core business to avoid unnecessary risk.
					</div>
				</>
			);
		} else {
			return <p onClick={() => setToggleA5(!toggleA5)}>Reveal Answer</p>;
		}
	};

	return (
		<>
			<div className="space-y-8">
				<div className="space-y-2">
					<p className="mainText text-[24px] tracking-tight font-black">Quizzes to Test Your Understanding</p>
					<p className="subText text-[20px] tracking-tight font-semibold">
						Let’s test your knowledge with these quick quizzes to help solidify what you've learned about Form S-1 and how to decode the Use of Proceeds section:
					</p>
				</div>

				<div className="space-y-12">
					<div className="space-y-2">
						<p className="subText text-[16px] tracking-normal font-light">
							<span className="font-semibold">Quiz 1:</span> A company plans to use 50% of the proceeds for "general corporate purposes" and the rest for "working
							capital." What does this tell you?
						</p>
						<li className="subText text-[16px] tracking-normal font-light">A) They have a clear plan for expansion</li>
						<li className="subText text-[16px] tracking-normal font-light">B) They might not be transparent about their financial situation</li>
						<li className="subText text-[16px] tracking-normal font-light">C) They are investing in new markets</li>
						<li className="subText text-[16px] tracking-normal font-light">D) They are repaying debt</li>

						<div className="py-4 px-8 tracking-tight font-semibold text-[16px] bg-slate-300 rounded-xl text-zinc-800">{renderA1()}</div>
					</div>

					<div className="space-y-2">
						<p className="subText text-[16px] tracking-normal font-light">
							<span className="font-semibold">Quiz 2:</span> If a company states that they will use the majority of proceeds for “strategic acquisitions,” what should
							you do next?
						</p>
						<li className="subText text-[16px] tracking-normal font-light">A) Assume the acquisitions will boost their business</li>
						<li className="subText text-[16px] tracking-normal font-light">B) Investigate whether their track record with acquisitions is strong</li>
						<li className="subText text-[16px] tracking-normal font-light">C) Ignore it, because acquisitions always work out</li>
						<li className="subText text-[16px] tracking-normal font-light">D) Worry about the company's financial stability</li>

						<div className="py-4 px-8 tracking-tight font-semibold text-[16px] bg-slate-300 rounded-xl text-zinc-800">{renderA2()}</div>
					</div>

					<div className="space-y-2">
						<p className="subText text-[16px] tracking-normal font-light">
							<span className="font-semibold">Quiz 3:</span> When a company says they are using proceeds for "debt repayment," what could be a good follow-up
							question?
						</p>
						<li className="subText text-[16px] tracking-normal font-light">A) What is the interest rate on the debt?</li>
						<li className="subText text-[16px] tracking-normal font-light">B) Are they planning to take on new debt?</li>
						<li className="subText text-[16px] tracking-normal font-light">C) How will this affect their overall expansion strategy?</li>
						<li className="subText text-[16px] tracking-normal font-light">D) All of the above</li>

						<div className="py-4 px-8 tracking-tight font-semibold text-[16px] bg-slate-300 rounded-xl text-zinc-800">{renderA3()}</div>
					</div>

					<div className="space-y-2">
						<p className="subText text-[16px] tracking-normal font-light">
							<span className="font-semibold">Quiz 4:</span> You see that a company plans to use 70% of proceeds for "working capital." What might this indicate?
						</p>
						<li className="subText text-[16px] tracking-normal font-light">A) They’re financially healthy and don’t need the cash</li>
						<li className="subText text-[16px] tracking-normal font-light">B) They might be struggling to cover day-to-day expenses</li>
						<li className="subText text-[16px] tracking-normal font-light">C) They’re preparing for rapid expansion</li>
						<li className="subText text-[16px] tracking-normal font-light">D) They’re looking to make acquisitions</li>

						<div className="py-4 px-8 tracking-tight font-semibold text-[16px] bg-slate-300 rounded-xl text-zinc-800">{renderA4()}</div>
					</div>

					<div className="space-y-2">
						<p className="subText text-[16px] tracking-normal font-light">
							<span className="font-semibold">Quiz 5:</span> A company is allocating proceeds for "strategic investments." What should you evaluate?
						</p>
						<li className="subText text-[16px] tracking-normal font-light">A) Whether the investment is in line with their core business</li>
						<li className="subText text-[16px] tracking-normal font-light">B) Whether the market is booming</li>
						<li className="subText text-[16px] tracking-normal font-light">C) Whether they have a strong balance sheet</li>
						<li className="subText text-[16px] tracking-normal font-light">D) Whether they are profitable</li>

						<div className="py-4 px-8 tracking-tight font-semibold text-[16px] bg-slate-300 rounded-xl text-zinc-800">{renderA5()}</div>
					</div>
				</div>
			</div>
		</>
	);
};
