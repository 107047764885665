// import packages
import { useState } from 'react';
import { motion } from 'motion/react';

// import components
import { HamburgerIcon } from '../icons/hamburger.jsx';

export const Navbar = () => {
	const [toggle, setToggle] = useState(false);

	const renderMenu = () => {
		if (toggle) {
			// Menu container animation variants
			const menuVariants = {
				hidden: { x: '100%', opacity: 0 },
				visible: { x: 0, opacity: 1 },
				exit: { x: '100%', opacity: 0 },
			};

			// Button animation variants
			const buttonVariants = {
				hidden: { opacity: 0, y: 10 },
				visible: i => ({
					opacity: 1,
					y: 0,
					transition: {
						delay: i * 0.2 + 0.3, // Sequential delay for each button
						duration: 0.4,
					},
				}),
				exit: i => ({
					opacity: 0,
					y: 10,
					transition: {
						delay: i * 0.2, // Sequential delay for exit in FIFO order
						duration: 0.3,
					},
				}),
			};

			const menuTransition = {
				type: 'spring',
				stiffness: 100,
				damping: 20,
				duration: 0.6,
			};

			// Menu buttons
			const menuItems = [
				{ label: 'Podcast', href: '/podcast' },
				{ label: 'Support Us', href: 'https://square.link/u/tFtalKxd' },
				{ label: 'Glossary', href: '/glossary', className: 'text-luif-cta-2' },
			];

			return (
				<>
					{/* Background overlay */}
					<motion.div
						onClick={() => setToggle(!toggle)}
						className="absolute top-0 left-0 bg-tipt-dark-bg h-screen w-screen z-10 opacity-60"
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.6 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}></motion.div>

					{/* Menu container */}
					<motion.div
						className="absolute top-0 right-0 bg-luif-bg-2 w-5/6 h-screen z-20"
						variants={menuVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
						transition={menuTransition}>
						<div className="relative top-8">
							<div className="mx-auto px-4">
								<div className="grid-cols-1 text-center space-y-4">
									{/* Menu buttons */}
									{menuItems.map((item, index) => (
										<motion.div
											key={index}
											variants={buttonVariants}
											initial="hidden"
											animate="visible"
											exit="exit"
											custom={index} // Pass index for sequential delay
											className="hover:scale-105 px-4 py-4 bg-zinc-900 w-full rounded-xl">
											<a className={`logo font-black text-[20px] uppercase ${item.className || ''}`} href={item.href}>
												{item.label}
											</a>
										</motion.div>
									))}
								</div>
							</div>
						</div>

						{/* Close button */}
						<div className="flex justify-center">
							<motion.button
								onClick={() => setToggle(!toggle)}
								className="absolute bottom-12 rounded-xl bg-luif-cta-2 text-zinc-800 px-4 py-2 w-5/6 logo font-black text-[20px] uppercase"
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: 20 }}
								transition={{ delay: 0.6, duration: 0.4 }}>
								Close
							</motion.button>
						</div>
					</motion.div>
				</>
			);
		} else {
			// Hamburger icon
			return (
				<div onClick={() => setToggle(!toggle)} className="md:hidden pr-4 flex justify-center items-center">
					<button className="text-green-400">
						<HamburgerIcon />
					</button>
				</div>
			);
		}
	};

	return (
		<nav className="bg-gray-900 text-white py-4">
			<div className="container mx-auto flex justify-between items-center">
				{/* Left: Logo or Brand */}
				<a href="/" className="text-[20px] logo pl-4 font-black tracking-tighter hover:tracking-wide">
					LVLUPINFINANCE
				</a>

				{/* Right: Navigation Links */}
				<div className="hidden md:flex space-x-6">
					<a href="/podcast" className="hover:scale-105 logo text-[16px] font-semibold tracking-tight uppercase">
						Podcast
					</a>
					<a href="https://square.link/u/tFtalKxd" className="hover:scale-105 logo text-[16px] font-semibold tracking-tight uppercase">
						Support Us
					</a>
					<a href="/glossary" className="hover:scale-105 text-luif-cta-2 logo text-[16px] font-semibold tracking-tight uppercase">
						Glossary
					</a>
				</div>

				{/* Mobile Menu */}
				{renderMenu()}
			</div>
		</nav>
	);
};
