// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

export const Privacy = () => {
	return (
		<>
			<Navbar />
			<div className="text-luif-text mx-auto px-8 sm:px-40 my-auto py-8 space-y-4">
				<h1 className="logo font-black text-[24px]">Privacy Policy</h1>

				<div>
					<p className="mainText font-black text-[20px]">1. Introduction</p>
					<p className="subText font-thin text-[16px]">
						At LVLUPINFINANCE, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard the personal information you
						provide when visiting our website and making donations through Square.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">2. Information We Collect</p>
					<p className="subText font-thin text-[16px]">When you interact with our website, we may collect the following information:</p>
					<ol className="subText font-thin text-[16px]">
						<li>Personal Information: If you choose to make a donation through Square, we may collect your name, email address, and payment information.</li>
						<li>Anonymous Data: We use SimpleAnalytics to track anonymous data about site visits, such as the number of visitors and the pages viewed.</li>
					</ol>
					<p className="subText font-thin text-[16px]">
						We do not collect any personal information unless you voluntarily provide it, such as by donating or contacting us via email.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">3. How We Use Your Information</p>
					<p className="subText font-thin text-[16px]">We use your information to:</p>
					<p className="subText font-thin text-[16px]">
						Process Donations: Your payment information is handled securely through Square, which processes your donation and provides us with confirmation details.
					</p>
					<p className="subText font-thin text-[16px]">
						Improve the Website: Anonymous usage data from SimpleAnalytics helps us enhance our site’s performance and user experience.
					</p>
					<p className="subText font-thin text-[16px]">
						Communicate Updates: If you provide your email address, we may send you occasional updates or information about new initiatives. You can opt out at any
						time.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">4. Sharing of Information</p>
					<p className="subText font-thin text-[16px]">
						We do not sell, rent, or trade your personal information to any third parties. However, we may share information with trusted service providers like Square,
						which helps process donations securely. Each third party, including Square and SimpleAnalytics, has its own privacy policy, and we recommend reviewing those
						policies.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">5. Security</p>
					<p className="subText font-thin text-[16px]">
						We take appropriate security measures to protect your data. While we strive to use industry-standard security protocols, please note that no method of
						transmission over the internet is completely secure.
					</p>
					<p className="subText font-thin text-[16px]">
						Your donations are processed through Square, which ensures the safety of your payment details. We do not store your payment information on our servers.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">6. Cookies and Tracking</p>
					<p className="subText font-thin text-[16px]">
						Our website does not use cookies to track personal information. We use SimpleAnalytics to anonymously track how users interact with our website without
						invading their privacy.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">7. Links to External Sites</p>
					<p className="subText font-thin text-[16px]">
						Our website contains links to third-party platforms such as TikTok and YouTube. These platforms may collect personal information, and we are not responsible
						for the privacy practices of these external websites. We encourage you to read the privacy policies of any third-party sites you visit.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">8. Your Rights</p>
					<p className="subText font-thin text-[16px]">You have the right to:</p>
					<p className="subText font-thin text-[16px]">Access the personal data we hold about you.</p>
					<p className="subText font-thin text-[16px]">Correct any inaccuracies in your information.</p>
					<p className="subText font-thin text-[16px]">Request deletion of your personal information, subject to legal obligations.</p>
					<p className="subText font-thin text-[16px]">If you wish to exercise any of these rights, please contact us at:</p>
					<p className="subText font-thin text-[16px]">Email: lvlupinfinance@gmail.com</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">9. Changes to this Privacy Policy</p>
					<p className="subText font-thin text-[16px]">
						We may update this Privacy Policy from time to time. All changes will be posted on this page, and the updated version will be effective immediately upon
						posting.
					</p>
				</div>

				<div>
					<p className="mainText font-black text-[20px]">10. Contact Us</p>
					<p className="subText font-thin text-[16px]">If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
					<p className="subText font-thin text-[16px]">Email: lvlupinfinance@gmail.com</p>
				</div>

				<div>
					<p className="mainText font-black text-[12px]">Last Updated: October 2024</p>
				</div>
			</div>
			<Footer />
		</>
	);
};
