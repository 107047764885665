// import packages
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

// import components
import { Navbar } from '../../components/navbar/index.jsx';
import { Footer } from '../../components/footer/index.jsx';
import { AudioPlayer } from '../../components/audio_player/index.jsx';
import { StockChartLoading } from '../../components/stock_chart_loading/index.jsx';

export const PodcastPage = () => {
	const [isVerified, setIsVerified] = useState(null); // null until verification completes
	const [isPlaying, setIsPlaying] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	// Helper function to store token in localStorage
	const storeToken = token => {
		localStorage.setItem('userToken', token);
	};

	// Helper function to remove token from localStorage
	const removeToken = () => {
		localStorage.removeItem('userToken');
	};

	// Helper function to check token expiration
	const isTokenExpired = token => {
		try {
			const payload = JSON.parse(atob(token.split('.')[1]));
			const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
			return payload.exp < currentTime;
		} catch (error) {
			console.error('Error checking token expiration:', error);
			return true; // If there's an error, consider the token as expired
		}
	};

	// Extract and store token from URL
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get('token');

		if (token) {
			storeToken(token);
			navigate('/podcast', { replace: true });
		}
	}, [location.search, navigate]);

	// Verify access with stored token
	useEffect(() => {
		const verifyAccess = async () => {
			const token = localStorage.getItem('userToken');
			if (!token || isTokenExpired(token)) {
				console.warn('Invalid or expired token found in localStorage');
				removeToken();
				navigate('/not-authorized', { replace: true });
				return;
			}

			try {
				const settings = {
					method: 'POST',
					headers: { Authorization: `Bearer ${token}` },
				};
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/check-verification`, settings);

				const data = await response.json();
				if (response.ok) {
					setIsVerified(true); // Set verification as successful
				} else {
					console.error('Verification failed:', data.message);
					removeToken();
					navigate('/not-authorized', { replace: true });
				}
			} catch (error) {
				console.error('Verification check failed:', error);
				removeToken();
				navigate('/not-authorized', { replace: true });
			}
		};

		verifyAccess();
	}, [navigate]);

	if (isVerified === null) return <StockChartLoading />; // Show loading state

	return (
		<div className="min-h-screen bg-gray-800 text-gray-800">
			<Navbar />
			<div className="">
				<div className="bg-gray-800">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4 text-zinc-50">
							<motion.h1
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.3, duration: 2 }}
								className="text-[32px] logo uppercase font-black">
								Welcome to the New LVLUPINFINANCE Podcast!
							</motion.h1>
							{/* <motion.h1 className="text-[32px] logo uppercase font-black">Welcome to the New LVLUPINFINANCE Podcast!</motion.h1> */}
							<motion.p
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.5, duration: 1.5 }}
								className="text-[16px] subText font-normal tracking-tight break-words">
								We’re thrilled to have you join us for our brand-new format unveiling, where we take a closer look at companies that you, our community, are buzzing
								about! Today, we’re diving into the innovative world of <strong>Oklo Inc.</strong>.
							</motion.p>
						</div>
						<div></div>
					</div>
				</div>

				<div className="bg-slate-50">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4 text-zinc-900">
							<motion.h1
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.4, duration: 1.3 }}
								className="text-[32px] logo uppercase font-black">
								Deep Dive on Oklo Inc.
							</motion.h1>
							<motion.p
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.6, duration: 1.6 }}
								className="text-[16px] subText font-normal tracking-tight break-words">
								In this episode, we break down Oklo Inc.'s recent developments and what makes them a rising star in the energy sector.
							</motion.p>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.8, duration: 1.8 }}
								className="py-4 rounded-xl drop-shadow-xl">
								<AudioPlayer />
							</motion.div>
						</div>
						<div></div>
					</div>
				</div>

				<div className="bg-gray-800">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-8 text-zinc-50">
							<motion.h1
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.5, duration: 1.2 }}
								className="text-[32px] logo uppercase font-black">
								Dive Deeper into Oklo’s Financials
							</motion.h1>
							<motion.p
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.4, duration: 1.4 }}
								className="text-[16px] subText font-normal tracking-tight break-words">
								Interested in Oklo’s financial standing? Check out their most recent SEC filing for an in-depth look at their financials, strategy, and market
								positioning.
							</motion.p>
							<div>
								<motion.a
									href="https://d18rn0p25nwr6d.cloudfront.net/CIK-0001849056/b7848154-3a7b-4860-953c-e3eb41ab74f9.pdf"
									target="_blank"
									rel="noopener noreferrer"
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ ease: 'easeOut', delay: 0.4, duration: 1.3 }}
									className="text-zinc-50 font-semibold underline m-0 p-0">
									View Oklo Inc.’s SEC Filing
								</motion.a>
								<motion.p
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ ease: 'easeOut', delay: 0.4, duration: 1.3 }}
									className="m-0 p-0 text-[12px] subText font-light tracking-normal">
									Filing Date: November 14, 2024
								</motion.p>
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
