// import pages
import { Landing } from './pages/landing/index.jsx';
import { Navbar } from './components/navbar/index.jsx';
import { Footer } from './components/footer/index.jsx';

export const App = () => {
	return (
		<>
			<Navbar />
			<Landing />
			<Footer />
		</>
	);
};
